import React, { useState, useCallback, useEffect, useContext } from 'react'
import { StoreContext } from "../../context/storeContext"
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const ProductCard = ({ product, images }) => {
    const lowPrice = Number(product.priceRange.minVariantPrice)
    const highPrice = Number(product.priceRange.maxVariantPrice)
    const image = images[0]?.asset.gatsbyImageData
    const alt = images[0]?.alt ? images[0].alt : ""

    const [unavailable, setUnavailable] = useState(true)

    const { client } = useContext(StoreContext)

    const checkAvailability = useCallback(
      productId => {
          client.product.fetch("gid://shopify/Product/"+productId).then(fetchedProduct => {
              const result = fetchedProduct
              setUnavailable(result.availableForSale)
          })
      },
      [client.product]
  )
  useEffect(() => {
    checkAvailability(product.id)
    }, [checkAvailability, product.id])


    return (
        <Link key={product.title} className='group card relative' to={`/products/${product.slug.current}`}>
            {images[0] ? (
            <div className='overflow-hidden'>
                <GatsbyImage className='transform hover:scale-105 transition-transform duration-700' image={image} alt={alt} />
            </div>
            ) : (
            <div className='square overflow-hidden'>
                <img className='absolute w-full h-full top-0 transform hover:scale-105 transition-transform duration-700 object-cover' width="" src={product.previewImageUrl} alt={product.title} />
            </div>
            )}
            <div className='p-5 text-center'>
                <h4 className='text-3xl text-text-dark group-hover:underline'>{product.title}</h4>
                {lowPrice !== highPrice ? (
                    <p>From £{lowPrice.toFixed(2)}</p>
                ) : (
                    <p>£{lowPrice.toFixed(2)}</p>
                )}
            </div>
            {!unavailable ? (
            <p className='text-text-light text-sm absolute top-2 px-2 py-2 bg-accent-1'>{!unavailable ? 'Sold out' : ''}</p>
            ) : null}
        </Link>
    )
}

export default ProductCard
