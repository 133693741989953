import React from 'react'
import ProductCard from './productCard'

const ProductGrid = ({ products }) => {
    return (
        <div className={`grid sm:grid-cols-2 gap-5 ${products.length < 4 ? 'lg:grid-cols-3' : 'lg:grid-cols-4'}`}>
            {products.map(product => (
                <ProductCard product={product.store} images={product.images} key={product.store.title} />
            ))}
        </div>
    )
}

export default ProductGrid
