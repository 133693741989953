import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Seo from "../components/utils/seo"
import ContentBlocks from '../components/homepage/contentBlocks'
import ProductGrid from "../components/products/productGrid"
const CollectionPage = ({ data }) => {
    const { collection, settings } = data
    return (
        <Layout>
            <Seo title={collection.seo?.title ? collection.seo.title : collection.title} description={collection.seo?.description ? collection.seo.description : collection.description} image={collection.seo?.image ? collection.seo.image : collection.image}>
                <script type="application/ld+json">
                    {`{
						"@context": "https://schema.org",
						"@type": "CollectionPage",
                        "name": "${collection.title}",
                        "image": "${collection.image ? collection.image.originalSrc : settings.seo.image.asset.url}",
                        "description": "${collection.description}",
                        "url": "${settings.general.url}/collections/${collection.handle}"
                    }`}
                </script>
            </Seo>
            <div className="container max-w-screen-xl mx-auto my-12 px-8 xl:px-0">
                <div className="mb-8 text-center">
                    <h1 className="text-5xl text-text-dark mb-5">{collection.title}</h1>
                    {collection.description && (
                        <p className="text-lg md:text-left">{collection.description}</p>
                    )}
                </div>
                <div className="mb-8">
                    <ContentBlocks contentBlocks={data.collection.contentBlocks} _rawContentBlocks={data.collection._rawContentBlocks} />
                </div>
                <ProductGrid products={collection.products} />
            </div>
        </Layout>
    )
}
export default CollectionPage

export const query = graphql`
    query sanityCollectionQuery($slug: String) {
        collection: sanityCollection(slug: {current: {eq: $slug}}) {
            title
            description
            ...CollectionPageContentBlocksFragment
            image {
                asset {
                    gatsbyImageData
                    url
                }
            }
            products {
                images {
                    alt
                    asset {
                      gatsbyImageData(aspectRatio: 1)
                    }
                  }
                store {
                    title
                    id
                    slug {
                        current
                    }
                    priceRange {
                        minVariantPrice
                        maxVariantPrice
                    }
                    previewImageUrl
                }
            }
            seo {
                title
                description
                image {
                    asset {
                        url
                    }
                }
            }
        }
        settings: sanitySettings {
            general {
                url
            }
            seo {
                image {
                    asset {
                        url
                    }
                }
            }
        }
    }
`